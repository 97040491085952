export const PUBLIC_PATHS = {
  //STAFF
  NEW_LOGIN: "/staff/auth/new-login",
  NEW_USER: "/staff/auth/new-user",
  LOGIN: "/staff/auth/login",
  NEW_PASS: "/staff/auth/new-password",
  FORGOT_PASS: "/staff/auth/forgot-password",
  CODE_VERIFICATION: "/staff/auth/code-verification",
  RESET_PASS: "/staff/auth/reset-password",

  // ADMIN ROUTES
  ADMIN_LOGIN: "/admin/auth/login",
};

export const PRIVATE_PATHS = {
  // STAFF
  DASHBOARD: "/staff/dashboard",
  PROFILE: "/staff/profile",
  SCHEDULE: "/staff/schedule",
  LOANS: "/staff/loans",
  REQUEST_LOAN: "/staff/loans/request",
  LOAN_DETAILS: "/staff/loans/:id",
  UPDATE_LOAN: "/staff/loans/:id/update",
  LEAVE: "/staff/leave",
  REQUEST_LEAVE: "/staff/leave/request",
  LEAVE_DETAILS: "/staff/leave-request/:id",
  UPDATE_LEAVE: "/staff/leave-request/:id/update",
  MEDICAL: "/staff/medical-assistance",
  REQUEST_MEDICAL: "/staff/medical-assistance/request",
  MEDICAL_DETAILS: "/staff/medical-assistance/:id",
  UPDATE_MED: "/staff/medical-assistance/:id/update",
  PERFORMANCE: "/staff/performance",
  PERFORMANCE_APPR_DETAILS: "/staff/performance/appraisals/:id",
  PERFORMANCE_REV_DETAILS: "/staff/performance/reviews/:reviewDate/:id",
  PERFORMANCE_REV_HISTORY: "/staff/performance/review-history/:reviewDate/:id",
  PERFORMANCE_REV: "/staff/performance/reviews/:id",

  // ADMIN ROUTES
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_PROFILE: "/admin/profile",
  ADMIN_EDIT_PROFILE: "/admin/update-profile",

  ADMIN_STAFF_PROFILE: "/admin/profiles/staffs",
  ADMIN_STAFF_PROFILE_DETAILS: "/admin/profiles/staffs/:id",
  ADMIN_ADD_STAFF: "/admin/profiles/staffs/create",
  ADMIN_ADMIN_PROFILE: "/admin/profiles/admins",
  ADMIN_ADMIN_PROFILE_DETAILS: "/admin/profiles/admins/:id",
  ADMIN_ADD_ADMIN: "/admin/profiles/admins/create",
  ADMIN_LOAN: "/admin/loans",
  ADMIN_LOAN_DETAILS: "/admin/loans/:id",
  ADMIN_ADD_LOAN: "/admin/loans/create",
  ADMIN_LEAVE_MGT: "/admin/leave-mgt",
  ADMIN_ADD_LEAVE_MGT: "/admin/leave-mgt/create",
  ADMIN_LEAVE_MGT_DEETAILS: "/admin/leave-mgt/:id",
  ADMIN_MEDICAL_ASSISTANCE: "/admin/medical-assistance",
  ADMIN_ADD_MEDICAL_ASSISTANCE: "/admin/medical-assistance/create",
  ADMIN_MEDICAL_ASSISTANCE_DETAILS: "/admin/medical-assistance/:id",
  ADMIN_STAFF_SCHEDULE: "/admin/staff-schedule",
  ADMIN_ADD_STAFF_SCHEDULE_LOCATION: "/admin/staff-schedule/create/location",
  ADMIN_EDIT_STAFF_SCHEDULE_LOCATION:
    "/admin/staff-schedule/edit/location/:week/:day/:id",
  ADMIN_ADD_STAFF_SCHEDULE_STAFF: "/admin/staff-schedule/create/staff",
  ADMIN_STAFF_SCHEDULE_LOCATION:
    "/admin/staff-schedule/location/:week/:day/:id",
  ADMIN_STAFF_SCHEDULE_LOCATION_WEEK:
    "/admin/staff-schedule/location/:week/:id",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_ADD_DEPT: "/admin/settings/department/create",
  ADMIN_VIEW_DEPT: "/admin/settings/department/:id",
  ADMIN_EDIT_DEPT: "/admin/settings/department/:id/edit",
  ADMIN_ADD_JOB: "/admin/settings/jobs/create",
  ADMIN_ADD_STAFF_ROLE: "/admin/settings/staff-roles/create",
  ADMIN_VIEW_STAFF_ROLE: "/admin/settings/staff-roles/:id",
  ADMIN_ADD_ROLE: "/admin/settings/roles/create",
  ADMIN_EDIT_ROLE: "/admin/settings/roles/:id",
  ADMIN_ADD_PERMISSION: "/admin/settings/permissions/create",
  ADMIN_VIEW_JOB: "/admin/settings/jobs/:id",
  ADMIN_VIEW_PERMISSION: "/admin/settings/permissions/:id",
  ADMIN_EDIT_JOB: "/admin/settings/jobs/:id/edit",

  ADMIN_PERFORMANCE: "/admin/performance",
  ADMIN_PERFORMANCE_APPR_DETAILS: "/admin/performance/appraisals/:id",
  ADMIN_PERFORMANCE_EDIT_PEN: "/admin/performance/appraisals/penalty/:id",
  ADMIN_PERFORMANCE_EDIT_REW: "/admin/performance/appraisals/reward/:id",
  ADMIN_ADD_REWARD: "/admin/performance/create-reward",
  ADMIN_ADD_PENALTY: "/admin/performance/create-penalty",

  ADMIN_INVENTORY_SETUP: "/admin/inventory/setup",
  ADMIN_INVENTORY_ADD_CAT: "/admin/inventory/setup/category/create",
  ADMIN_INVENTORY_ADD_ITEM: "/admin/inventory/setup/item/create",
  ADMIN_INVENTORY_PROCURE_OVERVIEW: "/admin/inventory/procure/overview",
  ADMIN_INVENTORY_PROCURE_VENDOR: "/admin/inventory/procure/vendors",
  ADMIN_INVENTORY_PROCURE_ITEMS: "/admin/inventory/procure/stock-items",
  ADMIN_INVENTORY_PROCURE_MAINTENANCE: "/admin/inventory/procure/maintenance",
  ADMIN_INVENTORY_PROCURE_PROCUREMENT: "/admin/inventory/procure/procurements",
  ADMIN_VIEW_PROCURE_PROCUREMENT: "/admin/inventory/procure/procurements/:id",
  ADMIN_INVENTORY_PROCURE_PROCUREMENT_CREATE:
    "/admin/inventory/procure/procurements-create",
  ADMIN_INVENTORY_PROCURE_CATEGORY: "/admin/inventory/procure/categories",
  ADMIN_ADD_VENDOR: "/admin/inventory/procure/vendors/create",
  ADMIN_VIEW_VENDOR: "/admin/inventory/procure/vendors/:id",
  ADMIN_VIEW_CATEGORY: "/admin/inventory/procure/categories/:id",
  ADMIN_EDIT_VENDOR: "/admin/inventory/procure/vendors/edit/:id",
  ADMIN_VIEW_ITEM: "/admin/inventory/procure/stock-items/:id",
  ADMIN_EDIT_ITEM: "/admin/inventory/procure/stock-items/edit/:id",
  ADMIN_FLAG_ITEM: "/admin/inventory/issuance/flag/:id/:locationId",
  ADMIN_INVENTORY_ISSUANCE: "/admin/inventory/issuance",
  ADMIN_INVENTORY_ISSUANCE_CREATE: "/admin/inventory/issuance/create",
  ADMIN_INVENTORY_ISSUANCE_DETAILS: "/admin/inventory/issuance/:id",
  ADMIN_INVENTORY_ISSUANCE_UPDATE: "/admin/inventory/issuance/:id/update",
  ADMIN_INVENTORY_REPORT: "/admin/inventory/report",
  ADMIN_INVENTORY_MAINTAIN: "/admin/inventory/maintain",
  ADMIN_INVENTORY_MAINTAIN_SEND: "/admin/inventory/maintain/:id/send",
  ADMIN_INVENTORY_MAINTAIN_VIEW: "/admin/inventory/maintain/:id",
  ADMIN_INVENTORY_REQUISITION: "/admin/inventory/requisitions",
  ADMIN_INVENTORY_REQUISITION_CREATE: "/admin/inventory/requisitions/create",
  ADMIN_INVENTORY_REQUISITION_DETAILS: "/admin/inventory/requisitions/:id",
  ADMIN_INVENTORY_REQUISITION_UPDATE:
    "/admin/inventory/requisitions/:id/update",
  ADMIN_INVENTORY_REPORTS: "/admin/inventory/reports",


  ADMIN_PERF_OVERVIEW: "/admin/performance/overview",
  ADMIN_PERF_SETUP: "/admin/performance/setup",
  ADMIN_PERF_SETUP_CREATE_KPI: "/admin/performance/setup/create-kpi",
  ADMIN_PERF_SETUP_CREATE_QUESTION: "/admin/performance/setup/create-question",
  ADMIN_PERF_SETUP_EDIT_QUESTION: "/admin/performance/setup/question/:id",
  ADMIN_PERF_TEAMS: "/admin/performance/teams",
  ADMIN_PERF_TEAMS_UPDATE: "/admin/performance/teams/:id/update",
  ADMIN_PERF_TEAMS_VIEW: "/admin/performance/teams/:id",
  ADMIN_PERF_TEAMS_CREATE: "/admin/performance/teams/create",
  ADMIN_PERF_REVIEWS: "/admin/performance/reviews",
  ADMIN_PERF_REVIEW_DETAILS: "/admin/performance/reviews/:staffId/:id",
  ADMIN_PERF_APPRAISALS: "/admin/performance/appraisals",
  ADMIN_PERF_CYCLES: "/admin/performance/cycles",
  ADMIN_PERF_CYCLES_DETAILS: "/admin/performance/cycles/:id",
  ADMIN_PERF_CYCLES_CREATE: "/admin/performance/cycles/create",
};
