const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN = "auth/login";
export const GET_PROFILE = BASE_URL + "admin/auth/profile";

export const ADMIN_LOCATIONS = "admin/location";

export const ADMIN_ROLES = "admin/roles";
export const ADMIN_DEPTS = "admin/department";
export const ADMIN_JOBS = "admin/job-title";
export const PERFORMANCE_MEASURE =
  "admin/performance-review/performance-measure/all";
export const PERFORMANCE_TEAMS = "admin/performance-review/team/all";
export const PERFORMANCE_TEAM_DETAILS = "admin/performance-review/team";
export const PERFORMANCE_REVIEWS = "admin/performance-review/staff-review/all";
export const PERFORMANCE_METRICS = "/admin/performance-review/metrics";
export const PERFORMANCE_REVIEW =
  "admin/performance-review/staff-review/details";
export const PERFORMANCE_CYCLES =
  "admin/performance-review/performance-review-cycle";
export const ALL_PERFORMANCE_CYCLES =
  "admin/performance-review/performance-review-cycle/all";
export const ADMIN_JOB = (id = "") => `admin/job-title/${id}`;
export const ADMIN_PERMISSIONS = "admin/permissions";
export const ADMIN_ROLES_PERMISSIONS = "admin/roles/permissions";
export const ADMIN_ROLES_LIST = (filterString = "", limit = "", page = "") =>
  `admin/roles?${filterString}&limit=${limit}&page=${page}&sort=createdAt,DESC`;

export const ADMIN_ROLE = (id = "") => `admin/roles/${id}`;
export const ADMIN_CREATE_REWARD = "admin/performance-review/reward";
export const ADMIN_CREATE_PENALTY = "admin/performance-review/penalty";
export const STAFF_METRICS = "admin/dashboard/staff/metrics";

export const ADMIN_PERMISSIONS_LIST = (
  filterString = "",
  limit = "",
  page = ""
) =>
  `admin/permissions?${filterString}&limit=${limit}&page=${page}&sort=createdAt,DESC`;

export const ADMIN_PERMISSION = (id = "") => `admin/permissions/${id}`;

export const UPLOAD_MEDIA = "system/media-upload";

export const STAFFS = "/admin/staff";
export const ADMINS = "/admin/users";
export const VENDORS = "/admin/inventory/vendor";
export const UPDATE_VENDOR = (id = "") => `admin/inventory/vendor/${id}`;
export const UPDATE_ITEM = (id = "") => `admin/inventory/stock-item/${id}`;
export const SEND_MAINTAIN = (id = "") =>
  `/admin/inventory/maintenance-request/send-for-maintenance/${id}`;
export const UPDATE_CAT = (id = "") => `admin/inventory/item-category/${id}`;
export const DEPTS = "/admin/department";
export const CATEGORIES = "/admin/inventory/item-category";
export const PROCUREMENT = "/admin/inventory/procurement";
export const ISSUANCE = "/admin/inventory/issuance";
export const DEPT = (id = "") => `/admin/department/${id}`;
export const REQUEST_LEAVE = "admin/leave-request";
export const REQUEST_MED = "admin/medical-assistance";
export const REQUEST_LOAN = "admin/loan-request";
export const PERFORMANCE = "admin/performance-review";
export const ADMIN_REQUEST_LEAVE = (id = "") => `admin/leave-request/${id}`;
export const ADMIN_REQUEST_LOAN = (id = "") => `admin/loan-request/${id}`;
export const STAFF_LIST = (filterString = "", limit = "", page = "") =>
  `admin/staff?${filterString}&limit=${limit}&page=${page}&sort=createdAt,DESC`;
export const STAFF = (id = "") => `admin/staff/${id}`;
export const PEN = (id = "") => `/admin/performance-review/penalty/${id}`;
export const REW = (id = "") => `/admin/performance-review/reward/${id}`;
export const ADMIN = (id = "") => `admin/users/${id}`;
export const ACTIVATE_STAFF = (id = "") => `admin/staff/activate/${id}`;
export const TERMINATE_STAFF = (id = "") => `admin/staff/terminate/${id}`;
export const UPDATE_EMPLOYEE_DOC = (id = "") =>
  `admin/employee-documents/${id}`;
export const UPDATE_DEPT = (id = "") => `admin/department/${id}`;
export const GET_STAFF_LEAVE_BALANCE = (id = "") =>
  `admin/leave-request/leave-balance/${id}`;
export const GET_STAFF_LOAN_BALANCE = (id = "") =>
  `admin/loan-request/loan-balance/${id}`;
export const APPROVE_LICENSE = (id = "") =>
  `admin/staff/approve-driver-license/${id}`;
export const EMPLOYEE_DOC = "admin/employee-documents";

export const APPROVE_LEAVE = (id = "") => `admin/leave-request/approve/${id}`;
export const REJECT_LEAVE = (id = "") => `admin/leave-request/reject/${id}`;
export const APPROVE_LOAN = (id = "") => `admin/loan-request/approve/${id}`;
export const APPROVE_MED = (id = "") =>
  `admin/medical-assistance/approve/${id}`;
export const LOAN_PAID = (id = "") =>
  `admin/loan-request/${id}/repayment-plan/set-to-paid`;
export const DISBURSE_LOAN = (id = "") => `admin/loan-request/disburse/${id}`;
export const REJECT_LOAN = (id = "") => `admin/loan-request/decline/${id}`;
export const REJECT_MED = (id = "") => `admin/medical-assistance/decline/${id}`;

export const UPDATE_ADMIN_PROFILE = "/admin/update-profile";
export const UPDATE_ADMIN_PASSWORD = "/admin/update-password";
export const ADMIN_CREATE_SCHEDULE_BY_LOCATION =
  "/admin/schedule/create-by-location";
export const ADMIN_EDIT_SCHEDULE_BY_LOCATION = "/admin/schedule/location";
export const ADMIN_CREATE_SCHEDULE_BY_STAFF = "/admin/schedule/create-by-staff";
export const GET_SCHEDULES = "/admin/schedule/week";
export const GET_SCHEDULE_WEEK = "/admin/schedule/location/week";

export const GET_SCHEDULES_DAY = (
  week = "",
  day = "",
  page = "",
  limit = "",
  search = ""
) =>
  `/admin/schedule/day?week=${week}&dayOfWeek=${day}&page=${page}&limit=${limit}&search=${search}`;

export const GET_SCHEDULES_LOCATION = (week = "", day = "", id = "") =>
  `/admin/schedule/location?week=${week}&dayOfWeek=${day}&locationId=${id}`;
export const DEL_SCHEDULES_LOCATION = (id = "") => `/admin/schedule/day/${id}`;

export const GET_SCHEDULES_LOCATION_WEEK = "/admin/schedule/location";
export const ADD_CAT = "/admin/inventory/item-category";
export const ADD_ITEM = "/admin/inventory/stock-item";
export const ADD_KPI = "/admin/performance-review/performance-measure";
export const ADD_TEAM = "/admin/performance-review/team";
export const REVIEW = "/admin/performance-review/staff-review";
export const ADD_QUESTION =
  "/admin/performance-review/performance-measure/question";
export const UPDATE_QUESTION = (id = "") =>
  `/admin/performance-review/performance-measure/question/${id}`;
export const UPDATE_CYCLE = (id = "") =>
  `/admin/performance-review/performance-review-cycle/${id}`;
export const UPDATE_TEAM = (id = "") => `/admin/performance-review/team/${id}`;
export const REQS = "/admin/inventory/requisition";
export const MAINTENANCE = "/admin/inventory/maintenance-request";
export const UPDATE_REQ = (id = "") => `admin/inventory/requisition/${id}`;
export const UPDATE_ISSUANCE = (id = "") => `admin/inventory/issuance/${id}`;
export const INVENT_METRICS = "/admin/inventory/metrics";
export const APP_REQ = (type = "", id = "") =>
  `/admin/inventory/procurement/${type}/${id}`;
export const APP_ISSUANCE = (type = "", id = "") =>
  `/admin/inventory/issuance/${type}/${id}`;
export const APP_REV = (staffId = "", performanceReviewCycleId = "") =>
  `/admin/performance-review/staff-review/approve?staffId=${staffId}&performanceReviewCycleId=${performanceReviewCycleId}`;
export const DENY_REV = "/admin/performance-review/staff-review/deny";
export const COMPLETE_MAINTAIN = (id = "") =>
  `/admin/inventory/maintenance-request/complete/${id}`;

export const EXPORT_REVIEWS = () =>
  `/admin/performance-review/staff-review/export`;
export const EXPORT_PENALTIES = () =>
  `/admin/performance-review/penalty/export`;
export const EXPORT_REWARDS = () => `/admin/performance-review/reward/export`;
