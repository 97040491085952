export const LOGIN = process.env.REACT_APP_BASE_URL + "staff/auth/login";
export const SEND_PASS_OTP =
  process.env.REACT_APP_BASE_URL + "staff/auth/password-reset";
export const VRIFY_PASS__OTP =
  process.env.REACT_APP_BASE_URL + "staff/auth/verify-reset-code";
export const UPDATE_PASS =
  process.env.REACT_APP_BASE_URL + "staff/auth/update-password";

export const GET_USER = "staff/auth/profile";
export const REQUEST_LEAVE = "staff/leave-request";
export const PRFORMANCE = "staff/performance-review";
export const REVIEW = "/staff/staff-review";
export const REVIEW_DETAILS = "/staff/staff-review/details";
export const REVIEW_HISTORY = "/staff/staff-review/details/kpi";
export const PENDING_REVIEW = "staff/staff-review/pending";
export const PREVIOUS_REVIEW = "/staff/staff-review/previous";
export const OVERALL = "staff/performance-review/overall-rating";
export const REQUEST_MED = "staff/medical-assistance";
export const LEAVE_BALANCE = "staff/leave-request/leave-balance";
export const REQUEST_LOAN = "staff/loan-request";
export const LOAN_BALANCE = "staff/loan-request/loan-balance";
export const UPLOAD_PIC = "system/media-upload";
export const SCHEDULE = "staff/schedule/current-week";
export const REFRESH_TOKEN = "/auth/refresh";
export const UPDATE_LEAVE = (id = "") => `staff/leave-request/${id}`;
export const UPDATE_REVIEW = (id = "") => `/staff/staff-review/${id}`;
export const WITHDRAW_LEAVE = (id = "") => `staff/leave-request/withdraw/${id}`;
export const WITHDRAW_LOAN = (id = "") => `staff/loan-request/withdraw/${id}`;
export const WITHDRAW_MED = (id = "") =>
  `staff/medical-assistance/withdraw/${id}`;
export const UPDATE_MED = (id = "") => `staff/medical-assistance/${id}`;
export const UPDATE_LOAN = (id = "") => `staff/loan-request/${id}`;
